//typography
@mixin standard-bodyfont {
  //new design body font
	font-size: 16px;
	line-height: 22px;
	font-family: Raleway;
	color: $body-font-color;
}

// headlines

@mixin H1-font-size {
	font-size: 48px;
	line-height: 50.4px;
	letter-spacing: 0.25;
	@include media-breakpoint-down(md) {
		font-size: 40px;
		line-height: 45px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 32px;
		line-height: 40px;
	}
}

@mixin H1-margins {
	margin: 40px 0 32px 0;
	@include media-breakpoint-down(sm) {
		margin: 32px 0 24px 0;
	}
	
	&.withSubheadline {
		margin: 40px 0 16px 0;
	}
}

@mixin H2-font-size {
	font-size: 36px; 
	line-height: 43.2px; 
	letter-spacing: 0.15;
	@include media-breakpoint-down(sm) {
		font-size: 26px;
		line-height: 30px;
	}
}

@mixin H2-margins {
	margin: 40px 0 24px 0;
	@include media-breakpoint-down(sm) {
		margin: 32px 0 24px 0;
	}
}

@mixin H3-font-size {
	font-size: 28px; 
	line-height: 33.6px; 
	letter-spacing: 0;
	&.small {
		font-size: 22px;
		line-height: 24.9px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 22px;
		line-height: 24.9px;
	}
}

@mixin H3-margins {
	margin: 28px 0;
	&.subheadline {
		margin: 0 0 48px 0;
		@include media-breakpoint-down(md) {
			text-align: center;
		}
		@include media-breakpoint-down(sm) {
			margin: 0 0 24px 0;
		}
	}
}

@mixin H4-font-size {
	font-size: 18px;
	line-height: 22.5px; 
	letter-spacing: 0.15; 
}

@mixin H4-margins {
	margin: 18px 0;
}

@mixin headlineColor($fontcolor) {
	h1, h2, h3, h4,
	.h1, .h2, .h3, .h4 {
		color: $fontcolor;
	}
}

@mixin ptNavtarget($additional) {
	
	.navtarget {
	  display: block;
	  height: 0;
	  position: relative;
		top: -(150+$additional) + px;	
	}

	@media only screen and (max-width: 991px) {  

		.navtarget {
			top: -(70+$additional)px;	
		}
	}

	@media only screen and (max-width: 767px) 	{
	  
	 	.navtarget {
	  	top: -(70+$additional)px;	
	  }
	  
	}


	@media only screen and (max-width: 350px) {  

		.navtarget {
			top: -(100+$additional)px;	
		}

	}
}

//images
@mixin addImageShadow {
	box-shadow: 0 3px 6px #00000029;
}

@mixin addImageBorderRadius {
	border-radius: 8px;
}

//header
@mixin showNavStart() {
  visibility: hidden;
  position: absolute;
  -webkit-transition-property: opacity, visibility;
  -moz-transition-property: opacity, visibility;
  -o-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  
  -webkit-transition-duration: .2s, 0s;
  -moz-transition-duration: .2s, 0s;
  -o-transition-duration: .2s, 0s;
  transition-duration: .2s, 0s;
  
  -webkit-transition-delay: 0s, .2s;
  -moz-transition-delay: 0s, .2s;
  -o-transition-delay: 0s, .2s;
  transition-delay: 0s, .2s;
}

@mixin showNavGoal() {
  -webkit-transition-delay:  0s,.2s;
  -moz-transition-delay:  0s,.2s;
  -o-transition-delay:  0s,.2s;
  transition-delay:  0s,.2s;
}


//typography
@mixin blueDottedList {
  ul {
	
		@include clearfix();
		
		margin-top: 16px;
		margin-bottom: 16px;
		width: 100%;
		
		@include media-breakpoint-down(sm) {
			margin-top: 8px;
			margin-bottom: 8px;
			width: 100%;
			&:first-child {
				margin-bottom: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
		}
				
		li {
			padding-right: 16px;
			position: relative;
			padding-left: 20px;
			margin-bottom: 16px;
			list-style: none;
			
			&:before {
				content: " ";
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background-color: $listContainer-listIcon-color;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 4px;
			}
			
			ul {
				padding-top: 8px;	
			}
		}

		&.half {
			float: left;
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			li {
				margin-bottom: 4px;
			}
		}
	}	
}