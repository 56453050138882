.container {
	
	padding-top: 50px;
	padding-bottom: 60px;
	@include media-breakpoint-down(sm) {
    padding-top: 45px;
		padding-bottom: 55px;
	}
		
	.row { 
  	margin-top: 24px;
  	&:first-child {
  		margin-top: 0px;
  	}
  }
  
}	

.first-container {
	margin-top: 120.4px;
	padding-top: 30px;
	@include media-breakpoint-down(sm) {
    padding-top: 25px;
	}
	
	h1, .h1 {
		margin-top: 0;
	}
	
}

@media only screen and (max-height: 780px)  { 
  .first-container {
    margin-top: 80px;
  }
}

.first-container.trypage.ptForAndroid {
	margin-top: 0px;
}


@media only screen and (max-width : 991px) { 
  
  .container.first-container { 
    margin-top: 58px;
  }
}


@media only screen and (max-width: 320px) { 
  .first-container {
    margin-top: 98px;
  }
}


.colorBGContainer {
	background: $colorBGContainer;
	
	&.cta,
	&.darkBlue {
		background: $ctaContainer-bg-color;
		color: $ctaContainer-font-color;
		@include headlineColor(#ffffff);
	}
	
	&.lightBlue {
	  background-color: #a5cee0;
	}
	
	&.footer {
		background: #000;
  	padding-top: 20px;
  	padding-bottom: 20px;
	}
}

.containerMarker {	
	position: relative;
	&:before {
		content:" ";
		display: inline-block;
		width: 28px;
		height: 43px;
		position: absolute;
		top: -24px;
		left: calc(50% - 14px);
		bottom: auto;
	}
	&.orangeWhite:before {
		background: url('/2013/img/icons-2020/marker-yellow-white.svg');
	}
	&.orangeGrey:before {
		background: url('/2013/img/icons-2020/marker-yellow-grey.svg');
	}
	&.greyBlue:before {
		background: url('/2013/img/icons-2020/marker-grey-blue.svg');
	}
	&.lightblue2Grey:before {
		background: url('../img/icons-2020/marker-lightblue2-grey.svg');
	}
	&.whiteLightblue2:before {
		background: url('../img/icons-2020/marker-white-lightblue2.svg');
	}	
	&.blueGrey:before {
		background: url('/2013/img/icons-2020/marker-blue-grey.svg');
	}
	&.greyLightblue:before {
		background: url('/2013/img/icons-2020/marker-grey-lightblue.svg');
	}
	&.lightblueWhite:before {
		background: url('/2013/img/icons-2020/marker-lightblue-white.svg');
	}
	&.whiteLightBlue:before {
		background: url('/2013/img/icons-2020/marker-white-lightblue.svg');
	}
	&.whiteBlue:before {
		background: url('/2013/img/icons-2020/marker-white-blue.svg');
	}
	&.blueWhite:before {
		background: url('../img/icons-2020/marker-blue-white.svg');
	}
	&.whiteGrey:before {
		background: url('/2013/img/icons-2020/marker-white-grey.svg');
	}
	&.greyWhite:before {
		background: url('/2013/img/icons-2020/marker-grey-white.svg');
	}
	
	&.markerNeutral:before {
		top: -29px;
		background: url('/2013/img/icons-2020/marker-neutral.svg');
		height: 45px;
	}
	&.greyWhitePlus:before {
		background: url('/2013/img/icons-2020/icon-circle-plus-grey.svg');
		width: 56px;
		height: 56px;
		top: -28px;
		left: calc(50% - 28px);
	}
	&.whiteGreyPlus:before {
		background: url('/2013/img/icons-2020/icon-circle-plus-white.svg');
		width: 56px;
		height: 56px;
		top: -28px;
		left: calc(50% - 28px);
	}
	&.whiteTurquoise:before {
		background: url('/2013/img/icons-2020/marker-white-turquoise.svg');
	}
	&.personFranziska:before {
		width: 90px;
		height: 90px;
		top: -48px;
		left: calc(50% - 45px);
		background: url('/2013/img/mapandmarket/img-franziska-baerneklau.png');
		background-size: cover;
		
	}
	&.infoGreyWhite:before {
		background: url('/2013/img/to-comparison/icon-circle-exclamation.svg');
		width: 53px;
	  height: 52px;
	  top: -30px;
	  left: calc(50% - 26.5px);
		z-index: 1;
	}
	&.markerTO:before {
		background: url('/2013/img/solutions/Tourenplanung/icon-territory-optimization.svg');
		width: 64px;
	  height: 64px;
	  top: -32px;
	  left: calc(50% - 32px);
		z-index: 1;
	}
	&.markerSustainability:before {
		background: url('/2013/img/solutions/Tourenplanung/icon-sustainability.svg');
		width: 64px;
	  height: 64px;
	  top: -32px;
	  left: calc(50% - 32px);
		z-index: 1;
	}
	
}

hr.containerMarker {
	margin: -23px 0 -20px;
	border: 0;
	height: 44px;
	&.personFranziska {
		height: 90px;
		margin: -47px 0 -43px 0;
		&:before {
			top: 0;
		}
	}
	&.markerSustainability {
	  height: 64px;
		margin: -32px 0 -28px 0;
		&:before {
			top: 0;
		}
	}
	&:before {
		top: 0;
	}
	&.markerNeutral {
		border-top: 4px solid #e6eff1;
		overflow: unset;
		width: 50%;
  	margin: 20px auto 0 auto;
  	height: 0;
	}
}

.buttonOnly {
	padding-top: 0!important;
	margin-top: -20px;
}

.headlineOnly {
	padding-bottom: 0!important;
	
	@include media-breakpoint-down(md) {
		&.md-lessWhitespace {
			h1, h2, h3 { 
				margin-bottom: -12px;
				margin-top: 0;
			}
		}
	}
}


