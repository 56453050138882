// -- BUTTONS

.buttonContainer {
	display: flex;
  flex-wrap: wrap;
	gap: 16px 32px;
	
	button,
	input[type="submit"] {
		font-weight: 600;
	}
	
	.button {
		
		font-family: Raleway;
		font-size: 17px;
		display: block;
		//float: left;
		border: 2px solid $buttonContainer-main-color;
		color: $buttonContainer-main-color;
		border-radius: 4px;
		padding: 16px 20px;
		background-color: $buttonContainer-secondary-color;
		text-transform: none;
		box-shadow: none;
		text-shadow: none;
		
		&:focus-visible {
			outline: none;
		}
		
		&:hover {
			background-color: $buttonContainer-main-color;
			color: $buttonContainer-secondary-color;
			text-shadow: none;
		}
		
		&.active {
			background-color: $buttonContainer-main-color;
			color: $buttonContainer-secondary-color;
			&:hover {
				background-color: $buttonContainer-secondary-color;
				color: $buttonContainer-main-color;
			}
		}
		
		&.noHover {
			&:hover {
				background-color: $buttonContainer-secondary-color;
				color: $buttonContainer-main-color;
			}
			&.active {
				&:hover {
					background-color: $buttonContainer-main-color;
					color: $buttonContainer-secondary-color;
				}
			}
		}
		
		&.noBorder {
			border-width: 0;
			padding: 20px 24px;
		}
		
		&.cta {
			background: $cta-button-font-color;
			color: $cta-button-bg-color;
			border-color: $cta-button-bg-color;
			&:hover {
				background: $cta-button-bg-color;
				color: $cta-button-font-color;
			}
			&.active {	
				background: $cta-button-bg-color;
				color: $cta-button-font-color;
				&:hover {
					background: $cta-button-font-color;
					color: $cta-button-bg-color;
				}
			}
		}
		
		&:disabled,
		&:disabled.cta {
	  	background: #c1c1c1;
	  	border-color: #c1c1c1;
	  	color: white;
	  	
	  	&:hover {
		  	background: #c1c1c1;
		  	border-color: #c1c1c1;
		  	color: white;
		  }
			
		}	
		
		&.button_loading {
			text-indent: -10000px;
			line-height: 0;
	  	background: $white;
	  	border-color: $cta-button-bg-color;
			.button-loader {
				display: block;
			}
	  	&:hover {
		  	background: $white;
		  	border-color: $cta-button-bg-color;
		  	color: white;
		  }
		  
		  &.active {
	  		border-color: $cta-button-bg-color;
		  	background: $cta-button-bg-color;
				.button-loader {
					color: white;
				}
		  }
		  
		} 
		
		
		&.video {
			border-color: #ffffff;
			padding-left: 50px;
			background-image: url('/2013/img/icons-2020/icon-play-button-blue-xlg-lg.svg');
			background-position: left center;
			background-repeat: no-repeat;
			color: #74a1bb;
			&:hover {
				background-image: url('/2013/img/icons-2020/icon-play-button-blue-hover-xlg-lg.svg');
				background-color: #ffffff;
				color: #00628F;
			}
		}
	
	  
	  .button-loader {
		  width: 6px;
		  height: 6px;
		  border-radius: 50%;
		  margin: 8px 24px;
		  position: relative;
		  color: $cta-button-bg-color;
		  box-sizing: border-box;
		  display: none;
		  animation: animButtonLoader 1s linear infinite alternate;
		  text-indent: -10000px;
		}

		@keyframes animButtonLoader {
		  0% {
		    box-shadow: -19px -8px , -7px 0,  7px 0, 19px 0;
		  }
		  33% {
		    box-shadow: -19px 0px, -7px -8px,  7px 0, 19px 0;
		  }
		  66% {
		    box-shadow: -19px 0px , -7px 0, 7px -8px, 19px 0;
		  }
		  100% {
		    box-shadow: -19px 0 , -7px 0, 7px 0 , 19px -8px;
		  }
		}
		
		&:disabled {
	  	background: #c1c1c1;
	  	border-color: #c1c1c1;
	  	color: white;
	  	&:hover {
		  	background: #c1c1c1;
		  	border-color: #c1c1c1;
		  	color: white;
		  }
		  
		  &.button_loading {
			  text-indent: -100000px;
				line-height: 0;
		  	background: $white;
		  	border-color: $cta-button-bg-color;
				.button-loader {
					display: block;
				}
		  	&:hover {
			  	background: $white;
			  	border-color: $cta-button-bg-color;
			  	color: white;
			  }
			  
			  &.active {
		  		border-color: $cta-button-bg-color;
			  	background: $cta-button-bg-color;
					.button-loader {
						color: white;
					}
			  }
			  
			}
		}
		
				
	}
	&.centeredButtons {
		justify-content: center;
	}
}