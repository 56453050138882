$body-font-color: #424242 !default;
$body-font-color-disabled: #a8a8a8 !default;
$primaryColor: #00628F !default;

$buttonContainer-main-color: #00628F !default;
$buttonContainer-secondary-color: #ffffff !default; //typically the fontColor

$listContainer-listIcon-color: #A5CEE0 !default;

$colorBGContainer: #E6EFF1 !default;
$ctaContainer-bg-color: #00628F !default;
$ctaContainer-font-color: #ffffff !default;
$ctaContainer-font-color-hightlight: #E5AF21 !default;


//inverted for hover
$cta-button-bg-color: #E5AF21 !default;
$cta-button-border-color: #E5AF21 !default;
$cta-button-font-color: #ffffff !default;

$blogCta-bg-color: #99DBE1 !default;
$blogCta-font-color: #003F5C !default;

$solution-firstContainer-bg-color: #ffffff !default;
$solution-firstContainer-font-color: $body-font-color !default;

$text-link-font-color: #74A1BB !default;
$text-link-font-color-hover: #00628F !default;

$text-link-arrow: url('../img/icons-2020/link-default.svg') !default;
$text-link-arrow-hover: url('../img/icons-2020/link-hover.svg') !default;

$faq-link-color: #00628F !default;

$shopBorderDefaultColor: #E6EFF1 !default;
$shopBorderHoverColor: #A5CEE0 !default;
$shopBorderActiveColor: #74A1BB !default;
$shopBorderDisabledColor: #F3F1EC !default;
$shopRadioButtonDefaultBorderColor: #A5CEE0 !default;

$shopDiscountColor: #008F48 !default;
$shopDiscountColorPassive: #B2DDC7 !default;
$shopDiscountColorNoSaving: #AB276D !default;
$shopDiscountColorNoSavingPassive: #C872A0 !default;
$shopDiscountColorDisabled: #cccccc !default;

$shopFormPlaceholder: #e6e6e6 !default;
$shopFormDisabledInput: #e6e6e6 !default;
$shopDisabledPlaceholder: #cccccc !default;
$shopDisabledLabel: #cccccc !default;
$errorFormField: #8F0E23 !default;


$shopDiscountHintColor: #A8A8A8 !default;

//marker für solutions pages sollten im jeweilgen farbschema definiert werden
  
/**
 * vorhandene Farben
 * #00628F
 * #ffffff coupon
 * #008F48 coupon
 * #A8A8A8
 * #cccccc
 * 
 */

/*
-- coupon 

#ffffff
border (für umrandungs ding) spezial fall nur diese seite -> muss weiß sein kein theme
background -> sendnow row -> muss weiß sein kein theme
font -> status des copy vorgangens spezial fall nur diese seite muss weiß sein kein theme

#008F48 
background status kein theme

#00628F52 
box shadow send now kein theme

#6CB4DD 
border -> umrandung kein theme

#A8A8A8
heller infotext am ende


-- home

#cccccc
-> seo links
 

-- landing-atf-general

#ffffff
video button border color && background-color hover

#74a1bb
video button background

#00628F
font video button hover


-- login

#A8A8A8
additional info 


theoretisch noch anzusehen: 

basic
general
job
job-hero
landign-aft-versions



*/