
.blueFont {
	color: #00628F;
}

.centered {
	h1, h2, h3, h4, p {
		text-align: center;
	}
}

.centeredHeadlines {
	h1, h2, h3 {
		text-align: center;
	}
}

.headlinesLeftAlways {
	h1, h2, h3, .subheadline {
		text-align: left;
	}
}

b {
	font-weight: 600;
}

blockquote {
	margin: 0;
	&.big {
		padding-left: 50px;
		p {
			margin-top: 0;
		}
	}
}

//
// TODO
//
//
//export to integration Container
a {
	&.hasLeftArrow:after {
		content: "";
		height: 16px;
		width: 10px;
		display: inline-block;
		margin-left: 4px;
	  background-repeat: no-repeat;
	  background-position: center center;
		background-image: url('/2013/img/icons-2020/arrow-left-default.svg');
	}
	&.hasLeftArrow:hover:after {
		background-image: url('/2013/img/icons-2020/arrow-left-hover.svg');
	}
	&.hasLeftArrow.isOpen:after {
		background-image: url('/2013/img/icons-2020/arrow-left-hover.svg');
	}
	
}


//
// TODO
//
//
//export to roi

a {
	&.hasDownArrow {
		color: #ffffff;
		&:after {
			content: "";
			height: 10px;
			width: 18px;
			display: inline-block;
			margin-left: 4px;
		  background-repeat: no-repeat;
		  background-position: center center;
		  background-size: cover;
			background-image: url('/2013/img/icons-2020/icon-arrow-down-white.svg');
		}
		&:hover:after {
			background-image: url('/2013/img/icons-2020/icon-arrow-down-white.svg');
		}
		&.isOpen:after {
			background-image: url('/2013/img/icons-2020/icon-arrow-up-white.svg');
		}
	}
}


//
// TODO
//
//
//export to ... coupon??

a {
	//icons
	&.icon:after {
    content: "";
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-left: 4px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    margin-bottom: -4px;
	}
	&.copy:after {
		background-image: url('/2013/img/icons-2020/icon-copy-link-default.svg');
	}
	&.copy:hover:after {
		background-image: url('/2013/img/icons-2020/icon-copy-link-blue.svg');
	}
	&.send:after {
		background-image: url('/2013/img/icons-2020/icon-send-default.svg');
	}
	&.send:hover:after {
		background-image: url('/2013/img/icons-2020/icon-send-hover.svg');
	}
}

.infoTextSmall {
	font-size: 14px;
	line-height: 21px;
}