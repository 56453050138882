/*
Styles for Zendesk Homepage -> directly called from the home page template
Leave AS IS!!
*/ 


@import 'bootstrap';
@import 'normalize';

@import '_main-color-scheme';
@import 'pt_mixins';
@import 'cookiebanner';

@import 'typography';
@import 'typo_headlines';
@import 'typo_links';
@import 'typo_lists';
@import 'typo_buttons';

body {
	@include standard-bodyfont;
}

//these pages dont need the xxl breakpoint. in fact it makes them too big so we "remove" it by overwriting it:
@media (min-width: 1350px) {
	.container {
	  max-width: 1128px;
	}
}

@import 'container';
@import 'content_successStory-Blog-Teaser';

.bootstrap {
	
.container {
	padding-top: 30px;
	h1.withSubheadline {
		margin-top: 0;
	}
}

.category {	
	background-color: #74a1bb;
  border-radius: 2px;
  padding: 4px 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  display: inline-block;
  opacity: 0;
  sup {
  	z-index: 0;
  }
}
	
a:hover {
	text-decoration: none;
}

.startingArticles {
	margin-top: 8px;
	
	.col-12 {
		@include media-breakpoint-down(sm) {
			margin-bottom: 16px;
		}
	}
	
	a { 
		padding: 24px;
		padding-top: 88px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 8px;
		margin: 0; 
		height: 100%;
		span {
			display: inline-block;
		}
		&:hover {
			box-shadow: inset 0px 3px 6px #00000029;
		}
		&.click {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-clicks-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-clicks-hover.svg');
			}
		}
		&.import {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-import-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-import-hover.svg');
			}
		}
		&.calc {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-calculate-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-calculate-hover.svg');
			}
		}
		&.list {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-protocol-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-protocol-hover.svg');
			}
		}
		&.cogwheel {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-activate-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-activate-hover.svg');
			}
		}
		&.manual {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-short-manual-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-short-manual-hover.svg');
			}
		}
		&.installation {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-installation-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-installation-hover.svg');
			}
		}
		&.leads {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-leads-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-leads-hover.svg');
			}
		}
		&.license {
			background: url('https://www.portatour.com/2013/img/zendesk/icon-licence-default.svg') 24px 24px no-repeat;
			&:hover {
				background-image: url('https://www.portatour.com/2013/img/zendesk/icon-licence-hover.svg');
			}
		}
	}
}

.headlineOnly {
	padding-top: 0;
}

.blogTeaserContainer {
	
	padding-bottom: 40px;
	
	h3 {
		margin-bottom: 0;
	}
	
	.teaserLink {
		p {
			font-size: 18px;
			line-height: 22.5px;
			font-weight: 600;
			color: #424242;
			span {
				font-weight: normal;
				display: block;
				font-size: 16px;
				line-height: 22px;
			}
		}
		.play {
			height: 76px;
			width: 76px;
			background: url('../img/abovethefold/icon-play-in-video-lg.svg') no-repeat center center;  
			background-size: contain;
			position: absolute;
		  left: calc(50% - 38px);
			top: 66px;
			text-indent: -10000px;
			@include media-breakpoint-only(md) {
				height: 56px;
				width: 56px;
			  left: calc(50% - 28px);
				top: 76px;
			}
			@include media-breakpoint-down(sm) {
				height: 20%;
				width: 20%;
			  left: calc(40%);
				top: calc(35%);
			}
			@include media-breakpoint-down(sm) {
				top: calc(20%);
			}
		}
		&:hover .play {
			background-image: url('../img/abovethefold/icon-play-in-video-hover-lg.svg');
		}
	}
	
}


.container.categoryCard {
	
	padding-top: 0;
	padding-bottom: 0;
	
	.row .col-12 {
		margin-bottom: 16px;
	}
	
	.hasInfo {
		align-items: center;
	}
	
	h4 {
		 margin: 0;
		 margin-bottom: 16px;
	}
	
	h3 {
		 margin: 0;
		 margin-bottom: 40px;
	}
	
	.content {
		padding: 40px 24px 16px 24px;
		border: 1px solid #74A1BB;
		border-radius: 8px;	
		
		a {
			margin-bottom: 24px;
			display: block;
			padding-left: 30px;
			flex-direction: column;
			&.help {
				background: url('https://www.portatour.com/2013/img/zendesk/icon-online-help-default.svg') no-repeat left top;  
				&:hover {
					background-image: url('https://www.portatour.com/2013/img/zendesk/icon-online-help-hover.svg');
				}
			}
			&.videos {
				background: url('https://www.portatour.com/2013/img/zendesk/icon-videos-default.svg') no-repeat left top;  
				&:hover {
					background-image: url('https://www.portatour.com/2013/img/zendesk/icon-videos-hover.svg');
				}
			}
			&.faq {
				background: url('https://www.portatour.com/2013/img/zendesk/icon-faq-default.svg') no-repeat left top;  
				&:hover {
					background-image: url('https://www.portatour.com/2013/img/zendesk/icon-faq-hover.svg');
				}
			}
			&.pdf {
				background: url('https://www.portatour.com/2013/img/zendesk/icon-manual-pdf-default.svg') no-repeat left top;  
				&:hover {
					background-image: url('https://www.portatour.com/2013/img/zendesk/icon-manual-pdf-hover.svg');
				}
			}
			
			&:hover {
				color: #00628f;
			}
			
			* {
				display: block;
			}
			strong {
				font-size: 18px;
				line-height: 22.5px;
				color: #74A1BB;
				font-weight: 600;
			}
			span {
				color: #424242;
			  font-weight: 400;
			}
			&:hover span {
				color: #424242;
			}
		}
		
		&.info {
			top: 0;
		  bottom: 0;
		  position: absolute;
		  
			h4 {
				margin-bottom: 16px;
				padding-top: 64px;
			}
		}
		
	}
	
	.info {
		align-self: center;
		margin-top: 48px;
  	margin-bottom: 48px;
		.content {
			padding: 0;
			border: none;	
			h4 {
				padding-top: 64px;
				background: url('https://www.portatour.com/2013/img/zendesk/icon-info.svg') no-repeat left top;  
			}
			
			a {
				padding-left: 0; 
			}
		}
	}

}

}