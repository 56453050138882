// -- HEADLINES

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
	display: block;
	font-weight: 600;
	font-family: Raleway;
	color: $body-font-color;
}

h1,
.h1 {
	@include H1-font-size;
	@include H1-margins;
	@include media-breakpoint-down(md) {
		text-align: center;
	}
}

h2,
.h2 {
	@include H2-font-size;
	@include H2-margins;
}

h3,
.h3 {
	font-weight: 400;
	@include H3-font-size;
	@include H3-margins;
}

h4,
.h4 {
	font-weight: 600;
	font-family: Raleway;
	@include H4-font-size;
	@include H4-margins;
}