// -- LNKS

a {
	font-weight: 500;
	color: $text-link-font-color;
	text-decoration: none;
	&:hover {
		color: $text-link-font-color-hover;
	}
	&.hasArrow:after {
		content: "";
		height: 10px;
		width: 18px;
		display: inline-block;
		margin-left: 4px;
	  background-repeat: no-repeat;
	  background-position: center center;
	  background-size: cover;
		background-image: $text-link-arrow;
	}
	&.hasArrow:hover:after {
		background-image: $text-link-arrow-hover;
	}
	&.white {
		color: #ffffff;
		&.hasArrow:after {
			background-image: url('../img/icons-2020/icon-link-white-default.svg');
		}
		&:hover {
			color: #74a1bb;
		}
		&.hasArrow:hover:after {
			background-image: url('../img/icons-2020/icon-link-white-hover.svg');
		}
	}
}