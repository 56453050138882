// -- LISTS


ul {
	padding: 0;
}

.listContainer {
	
	@include clearfix();
	
	@include blueDottedList();
}

.blueDottedUL {
	@include blueDottedList();
}

/*
//old list containers

ul {
	padding: 0;
}

.listContainer {
	@include clearfix();

	margin-top: 16px;
	@include media-breakpoint-down(sm) {
			margin-top: 8px;
	}

	ul.blueDot {
		
		margin-bottom: 16px;
		margin-top: 0px;
		width: 100%;
		
		@include media-breakpoint-down(sm) {
			margin-bottom: 8px;
			width: 100%;
			&:first-child {
				margin-bottom: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
		}
				
		li {
			padding-right: 16px;
			position: relative;
			padding-left: 20px;
			margin-bottom: 16px;
			list-style: none;
			
			&:before {
				content: " ";
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background-color: $listContainer-listIcon-color;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 4px;
			}
			
			ul {
				padding-top: 8px;	
			}
		}
	
		&.half {
			float: left;
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			li {
				margin-bottom: 4px;
			}
		}
	}
}
*/