
.storiesTeaserContainer {
	.col-md-6,
	.col-sm-6 {
		margin-top: 94px;
		margin-bottom: 24px;
	}
	
	.buttonContainer {
		padding-top: 16px;
	}
	
	.showMD {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
		}		
	}
} 

.blogTeaserContainer,
.pressTeaserContainer {
	.buttonContainer {
		padding-top: 16px;
	}
	@include media-breakpoint-down(sm) {
		.col-12 {
			margin-bottom: 24px;
		}
	}
	
	.showMD {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
		}		
	}
}

.successStoryTeaser,
.blogTeaser,
.pressTeaser  {
	height: 100%;
	background: #ffffff;
	position: relative;
	flex: 1;
	border-radius: 4px;
	  
	&:hover {
		box-shadow: 0px 4px 9px #a8a8a8;
	}
	@include media-breakpoint-down(sm) {
		box-shadow: 0px 4px 9px #a8a8a8;
	}
	
	
	
	a.teaserLink {
		color: $body-font-color;
		font-weight: normal;
		&:hover {
			color: $text-link-font-color-hover;
		}
	}
	
	img {
		&.client {
			position: absolute;
			width: 148px;
			right: 24px;
			margin-top: -148px;
		}
		
		&.logo {
			margin-left: 10px;
			max-height: 72px;
		}
		
		&.blogImage {
			display: block;
			width: 100%;
			border-radius: 4px;
		}
	}
	
	&.remmers,
	&.rmi-miwa {
		img.logo {
			margin-left: 23px;
		}
	}
	
	&.sin {
		img.logo {
			margin-left: 24px;
		}
	}
	
	&.ksmedizintechnik {
		img.logo {
			margin-left: 14px;
		}
	}
	
	&.sonax {
		img.logo {
			margin-left: 8px;
		}
	}
	
	&.feigl,
	&.hdplus {
		img.logo {
			max-height: 40px;
  		margin: 16px 0 16px 24px;
		}
	}
	
	&.datacol {
		img.logo {
			max-height: 20px;
  		margin: 26px 0 26px 24px;
		}		
	}
	
	&.maintea {
		img.logo {
			max-height: 40px;
  		margin: 16px 0 16px 24px;
		}
	}
	
	&.krebshilfe {
		img.logo {
			max-height: 50px;
  		margin: 11px 0 11px 24px;
		}
	}
	
	&.reyher {
		img.logo {
  		margin-left: 23px;
		}
	}
	
	&.avm {
		img.logo {
  		margin-left: 25px;
		  height: 46px;
		  margin-top: 13px;
		  margin-bottom: 13px;
		}
	}
	
	&.wildbeauty {
		img.logo {
  		margin-left: 25px;
		  height: 26px;
		  margin-top: 30px;
		  margin-bottom: 16px;
		}
	}
	
	&.granalu {
		img.logo {
  		margin-left: 18px;
		  height: 38px;
		  margin-top: 17px;
		  margin-bottom: 17px;
		}
	}
	
	&.muepro,
	&.jpms {
		img.logo {
  		margin-left: 25px;
		  height: 30px;
		  margin-top: 21px;
		  margin-bottom: 21px;
		}
	}
	
	&.fripoo,
	&.minderleinsmuehle,
	&.zdl,
	&.dux,
	&.tonys-chocolonely,
	&.avz,
	&.atko,
	&.hela,
	&.cpd {
		img.logo {
  		margin-left: 25px;
		  height: 50px;
		  margin-top: 11px;
		  margin-bottom: 11px;
		}
	}
	
	&:hover {
		img.blogImage { border-radius: 4px 4px 0 0; }
		
	}
	
	.quote,
	.intro { 
		padding: 8px 24px 16px 24px;
		p {
			font-size: 18px;	
			line-height: 22.5px;
			margin: 0 0 16px 0;
		}
	}
	
	.author {
		font-size: 16px;	
		line-height: 22px;
		color: #a8a8a8;
		margin-bottom: 46px;
		display: block;
		margin: 0 0 12px 0;
	}
	
	.hasArrow {
		font-size: 16px;	
		line-height: 22px;
		position: absolute;
		right: 24px;
  	bottom: 19px;
	}
}

.successStoryTeaser {
	padding-top: 74px;
}

.blogTeaser,
.pressTeaser {
	.intro {
		padding-bottom: 12px;
		p {
			margin-top: 16px;
		}	
	}
}

.storiesTeaserContainer,
.blogTeaserContainer,
.pressTeaserContainer {
	&.hasExtraLink {
		.successStoryTeaser,
		.blogTeaser,
		.pressTeaser { 
			@include clearfix();
			box-shadow: 0px 4px 9px #a8a8a8;
  		padding-bottom: 24px;
		}
		
		a.extra {
			float: right;
			margin-right: 24px;
			padding-right: 12px;
			font-size: 13px;
			line-height: 18px;
			font-weight: 500;
  		background: url('../img/icons-2020/blog/icon-chevron-right-default.svg') center right no-repeat;
  		background-size: 6px;
  		&:hover {
  			background-image: url('../img/icons-2020/blog/icon-chevron-right-hover.svg');
  		}
		}
				
	}
}

.blogTeaser {
	.intro {
		.category {
			margin-top: 16px;
			display: inline-block;
			padding: 10px;
			color: white;
			font-size: 14px;
			line-height: 21px;
			background: #74A1BB;
			border-radius: 2px;
		}	
	}
}